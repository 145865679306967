@import '../icons';

.container {
  position: relative;
  width: 100%;
}

$border-color: #ced4da;
$border-radius: 0.25rem;
$animation-speed: 0.4s;

// Правила позаимствованные из Bootstrap
.control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $border-color;
  appearance: none;
  border-radius: $border-radius;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out, border-radius $animation-speed;
  &:focus {
    // border-color: rgb(13 110 253 / 25%);
    outline: 0;
  }
  &:disabled {
    background-color: #e9ecef;
  }
}

.dropdown {
  opacity: 0;
  visibility: hidden;
  background: white;
  position: absolute;
  min-width: 100%;
  border: 1px solid $border-color;
  border-radius: 0 0 $border-radius $border-radius;
  padding: 5px 5px;
  transition: opacity $animation-speed, visibility $animation-speed;
  z-index: 100;
}

.container_opened {

  & .control {
    border-radius: $border-radius $border-radius 0 0;

    &:global.is-invalid {
      color: red;
      background: red;
    }
  }
  & .dropdown {
    visibility: visible;
    opacity: 1;
    border-top: 0;
  }
}

.container_invalid {
  .control {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: $icon-invalid;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.container_valid {
  .control {
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: $icon-valid;
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
}



.links {
  & .link {
    color: #0d6efd;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    padding: 5px 10px;
    margin: 0 auto;
    // border: 1px solid red;
    &:hover {
      color: #284a8d;
      background: #87CEFA44;
      text-decoration: underline;
    }
  }
}