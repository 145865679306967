@import "../icons";

.root {
  position: relative;
  display: inline-block;
}

.dropdown {
  display: block;
  margin-top: 10px;
  z-index: 20;
  position: absolute;
  &.filled {
    background: white;
    border: 1px solid #aaa;
  }
}

.hidden {
  display: none;
}
