.root {
    width: 100%;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: collapse;
    $animation-speed: 0.4s;
    transition: opacity $animation-speed, visibility $animation-speed;
}

.progressHolder {
    $min-height: 10px;
    $border-radius: 20px;
    $progress-width: 30px;
    $animation-speed: 1.0s;

    width: 100%;
    min-height: $min-height;
    border-radius: $border-radius;
    background: #97b2ea33;

    @keyframes myanim {
        from {
            left: 0;
        }
        to {
            left: calc(100% - #{$progress-width});
        }
    }

    .progress {
        position: relative;
        width: $progress-width;
        height: 100%;
        left: 0;
        border-radius: $border-radius;
        min-height: $min-height;
        background: #97b2ea;

        animation-duration: $animation-speed;
        animation-name: myanim;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }
}

.root_loading {
    .select {
        background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="iso-8859-1"%3F%3E%3C!--Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)--%3E%3Csvg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23.612 23.612" style="enable-background:new 0 0 23.612 23.612" xml:space="preserve"%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath style="fill:%23010002" d="M16.192,5.224V4.487h-8.77v0.737c0,0,1.334,3.713,3.838,5.428v1.785c0,0-2.761,2.686-3.838,5.775 v0.842h8.77v-0.842c-1.399-3.41-3.837-5.775-3.837-5.775v-1.785C15.759,7.726,16.192,5.224,16.192,5.224z"/%3E%3C/g%3E%3Cg%3E%3Cpath style="fill:%23010002" d="M19.353,3.856V2.529h1.258V0H3.002v2.529h1.259v1.327c0,2.025,3.634,7.555,3.804,7.955 c-0.167,0.397-3.804,5.929-3.804,7.946v1.325H3.002v2.53h17.609v-2.53h-1.258v-1.325c0-2.025-3.635-7.521-3.829-7.951 C15.718,11.376,19.353,5.88,19.353,3.856z M18.096,19.757v1.325H5.519v-1.325c0-1.455,3.854-7.222,3.854-7.951 s-3.854-6.495-3.854-7.95V2.529h12.578v1.327c0,1.455-3.886,7.221-3.886,7.95C14.21,12.535,18.096,18.302,18.096,19.757z"/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E%0A') !important;
        background-size: 20px 20px !important;
    }
    .overlay {
        opacity: 1;
        visibility: visible;
    }
}