.navbar {
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 0 0.7rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 2.5rem;
  margin-top: 10px;

  .header {
    min-width: 300px;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #1d448f !important;
    padding: 0 10px;
    text-decoration: none;
    user-select: none;
    margin-right: auto;

    & img {
      margin-right: 15px;
      width: 48px;
      height: 48px;
    }
  }

  .links {
    .link {
      padding: 20px 15px;
      text-decoration: none;
      font-size: 1.1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

.block {
  display: block;
  padding: 10px 20px !important;
}