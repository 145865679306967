.root {
  font-family: 'Arial', serif;
  font-size: 14px;
  position: relative;
  border-bottom: 1px solid black;
}

.holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  & > * {
    flex: 0 1 auto;
  }
  .frame {
    margin-top: 10px;
    flex: 1 1 auto;
  }
}

.size_large {
  font-size: 16px;
}

.size_normal {
  font-size: 14px;
}

.text_1 {
  color: #000080;
}
.text_2 {
  color: #800000;
}

$card-left-margin: 30%;

.card {
  height: 500px;
  margin-left: $card-left-margin;
  padding: 10px 15px;
  border-left: 1px solid black;
}

.table_1 {
  border-collapse: collapse;
  th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  td {
    padding: 2px 5px;
  }
}

.left_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  padding: 0 10px;
  margin-top: 15px;

  .formName {
    margin-top: 10px;
    text-align: center;
  }

  .logo_block {
    & > img {
      max-width: 100%;
    }
  }

  .image_qr img {
    width: 170px;
    height: 170px;
  }
}
