.container {

  $min-height: 7px;
  $border-radius: 20px;
  $progress-width: 30px;
  $animation-speed: 1.0s;

  width: 100%;
  min-height: $min-height;
  border-radius: $border-radius;
  margin-top: 6px;
  margin-bottom: 6px;
  background: #97b2ea33;
  //opacity: 0;
  //transition: opacity 0.3s;
  display: none;

  @keyframes myanim {
    from {
      left: 0;
    }
    to {
      left: calc(100% - #{$progress-width});
    }
  }

  .progress {
    position: relative;
    width: $progress-width;
    left: 0;
    border-radius: $border-radius;
    min-height: $min-height;
    background: #97b2ea;

    animation-duration: $animation-speed;
    animation-name: myanim;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

.visible {
  // opacity: 1;
  display: block;
}