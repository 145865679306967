html {
  background: #ebf3ff;
}
body {
  background-color: unset !important;
}

button.btn:disabled {
  cursor: not-allowed;
  filter: grayscale(40);
}

.hide {
  display: none;
}

.text-right {
  text-align: right;
}

@media print {
  @page {
    margin: 0;
  }
}

.font-button {
  font-size: 18px !important;
}

.alert {
  &.alert-rounded {
    border-radius: 10px;
  }

  &.alert-border {
    &.alert-warning {
      border: 1px solid hsl(45, 94%, 41%);
    }
  }
}

.features {
  display: grid;
  gap: 20px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 1fr;
    justify-items: center;
    justify-content: center;
  }
  & .feature-card {
    text-decoration: none;
    color: black;
    display: flex;
    background: white;
    padding: 10px 15px;
    border-radius: 15px;
  }
}
