
.alert.alert-warning {
  background: yellow;
  border-color: #664d03;
}

.features {
  & > .feature-card {
    border: 1px solid black;
  }

  .feature-card:nth-child(1) {
    background: #64efd5 !important;
  }

  .feature-card:nth-child(2) {
    background: #fbdc9b !important;
  }

  .feature-card:nth-child(3) {
    background: #9ba9f4 !important;
  }
}

.search_controls {
  background: #64f0d5 !important;
  background: linear-gradient(90deg, rgba(98,240,213,1) 0%, rgba(0,225,255,1) 100%) !important;

  .nav-link {
    //color: white !important;;
  }
}
