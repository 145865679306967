
.navbar {
  width: 21cm;
  margin: 15px auto;
}

.button {
  color: black;
  &:disabled {
    background: red !important;
    border: 1px solid darkred !important;
    filter: grayscale(0.4) !important;
  }
}

// https://stackoverflow.com/a/21795173
.page {
  width: 21cm;
  height: 29.7cm;
  // margin: 30mm 45mm 30mm 45mm;
  margin: 10px auto;
  background: white;
  color: black;
  border: 1px solid black;
  box-shadow: 10px 5px 5px #555;
  position: relative;

  @media print {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    margin: 0;
  }
}

.overlay {
  background: #0d6efdaa;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;

  @media print {
    display: none;
  }

  & .block {
    border-radius: 20px;
    background: #ebf3ff;
    border: 1px solid #555;
    padding: 40px 20px;
    margin: 20px;
  }
}

.grayscale {
  filter: grayscale(100);
}

.page_content {
  padding: 0.3in;
  // TODO: Справа игнорируется padding
  overflow: hidden;
  height: 100%;
}

.html {
  background: #5f6368 !important;
}

.body {
  color: white;
}
