.controlHolder {
  display: flex;
  align-items: center;
  padding: 5px 0;
  // background: red;

  .label {
    // background: yellow;
    padding-right: 15px;
    width: 200px;
    text-align: right;
  }

  .control {
    // background: blue;
  }

  @media screen and (max-width: 568px) {
    // background: red;
    flex-direction: column;
    align-items: baseline;

    .label {
      padding-bottom: 5px;
      text-align: left;
    }
  }
}

.controls {
  margin: 0 auto;
  padding: 20px 30px;
  max-width: 868px;
  background: #97b2ea33;
  border-radius: 15px;
}

.captcha_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.pills {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px !important;

  :global(.nav-item .nav-link) {
    margin: 0 10px;
    &:not(.active) {
      border: 1px solid #0d6efd;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  :global(.btn) {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      font-size: 1.25em;
      font-weight: bold;
    }
  }
}
